import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom'
import _ from 'lodash';

import { usePopper } from 'react-popper';

import * as Icon from 'react-bootstrap-icons';

import yupInstance from '../../../components/yupInstance';

import { toast } from 'react-toastify';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Nestable from 'react-nestable';

import NavbarBar from "../../partials/Navbar/NavbarBar";
import LangSwitch from "../../partials/CreateEdit/LangSwitch/LangSwitch";
import NestableListAccordionItem from "../../partials/CreateEdit/NestableList/NestableListAccordionItem";

import {sendFetchGroupDataRequest, sendGroupUpdateRequest} from "../../../helpers/api-requests/phone-book";
import {sendListLanguagesRequest} from "../../../helpers/api-requests/languages";

import classNames from "classnames";
import {Alert, Button, Checkbox, Input} from "react-daisyui";
import Spinner from "../../partials/Spinner";
import PageTitle from "../../partials/CreateEdit/PageTitle/PageTitle";

export default function EditPhoneBookGroup() {
    const { group_id } = useParams();
    const navigate = useNavigate();

    const [responseData, setResponseData] = useState([]);
    const [fetchingData, setFetchingData] = useState(false);
    const [errors, setErrors] = useState('');
    const [availableLanguages, setAvailableLanguages] = useState({});
    const [defaultLanguage, setDefaultLanguage] = useState('');

    const [fullWidthTab, setFullWidthTab] = useState('contacts');
    const [activeTab, setActiveTab] = useState('general');
    const [dataLanguage, setDataLanguage] = useState('');

    const [formData, setFormData] = useState({});

    const fetchGroupData = async e => {
        const languagesResponse = await sendListLanguagesRequest({
            'orderBy': 'created_at',
            'order': 'desc',
        }, () => {
            setFetchingData(true);
        });

        if(languagesResponse.status === 200) {
            let newAvailableLanguages = {};

            languagesResponse.data.map(function(item) {
                _.set(newAvailableLanguages, item.code, item.display_name);
            });

            setAvailableLanguages(newAvailableLanguages);

            setDefaultLanguage(languagesResponse.meta.default_language.code);
            setDataLanguage(languagesResponse.meta.default_language.code);

            const groupsResponse = await sendFetchGroupDataRequest(group_id, () => {
                setFetchingData(true);
            },() => {
                setFetchingData(false);
            });

            if(groupsResponse.status === 200) {
                setResponseData(groupsResponse.data);
                setFormData(groupsResponse.data);
            } else if(groupsResponse.status === 404) {
                navigate("/phone-book", {
                    replace: true,
                });
            }
        }
    }

    const trySaveFormData = async e => {
        const schema = yupInstance.object().shape({
            name: yupInstance.object(_.map(availableLanguages, (value, key) => {
                if(key === defaultLanguage) {
                    return {[key]: yupInstance.string().required().min(3).max(255)};
                } else {
                    return {[key]: yupInstance.string().nullable().minNullable(3).max(255)};
                }
            }).reduce((a, b) => Object.assign(a, b), {})),
            hidden: yupInstance.boolean().required(),
            priority: yupInstance.number().required().integer().min(0),
            contacts:  yupInstance.array().of(
                yupInstance.object().shape({
                    title: yupInstance.object(_.map(availableLanguages, (value, key) => {
                        if(key === defaultLanguage) {
                            return {[key]: yupInstance.string().required().max(64)};
                        } else {
                            return {[key]: yupInstance.string().nullable().max(64)};
                        }
                    }).reduce((a, b) => Object.assign(a, b), {})),
                    prefix: yupInstance.object(_.map(availableLanguages, (value, key) => {
                        return {[key]: yupInstance.string().nullable().max(64)};
                    }).reduce((a, b) => Object.assign(a, b), {})).canBeNull(),
                    description: yupInstance.object(_.map(availableLanguages, (value, key) => {
                        return {[key]: yupInstance.string().nullable().max(64)};
                    }).reduce((a, b) => Object.assign(a, b), {})).canBeNull(),
                    numbers:  yupInstance.array().of(
                        yupInstance.object().shape({
                            phone_number: yupInstance.string().required().max(64),
                        })
                    ),
                })
            ),
        });

        schema.validate(formData, { abortEarly: false }).then(async () => {

            console.log('success');
            const response = await sendGroupUpdateRequest(group_id, formData, () => {
                setFetchingData(true);
            },() => {
                setFetchingData(false);
            });

            if(response.status === 200) {
                toast.success(response.message, {
                    hideProgressBar: true,
                });

                fetchGroupData();
            }

            setErrors({...response});
        }).catch((error) => {
            setErrors('');

            error.inner && error.inner.forEach(({ message, path }) => {
                path = path.replaceAll('[', '.').replaceAll(']', '');

                console.log(message);
                console.log(path);


                let newErrors = {...errors.errors};

                newErrors[path] = [message];

                setErrors({ errors: newErrors });
            });
        });
    }

    const handleFormDataChange = (wireKey, value, language) => {
        wireKey += language ? '.' + language : '';

        let newFormData = {...formData};

        _.set(newFormData, wireKey, value);

        setFormData(newFormData);
    }

    const handleReorder = (e, wireKey) => {
        console.log(e);

        let newFormData = {...formData};

        _.set(newFormData, wireKey, e.items.map(item => _.omit(item, 'children')));

        setFormData(newFormData);
    }

    const handleRemoveItem = (wireKey, type) => {
        let newFormData = {...formData};

        const keysList = wireKey.split('.');
        const index = _.last(keysList);

        _.pullAt(keysList, keysList.length - 1);

        wireKey = keysList.join('.');

        const removedItem = _.pullAt(_.get(newFormData, wireKey), [index]);

        if(type === 'contact') {
            'removed_contacts' in newFormData ? _.set(newFormData, 'removed_contacts', [...newFormData.removed_contacts, ...removedItem]) : _.set(newFormData, 'removed_contacts', [...removedItem]);
        }
        else if(type === 'number') {
            'removed_numbers' in newFormData ? _.set(newFormData, 'removed_numbers', [...newFormData.removed_numbers, ...removedItem]) : _.set(newFormData, 'removed_numbers', [...removedItem]);
        }

        setFormData(newFormData);
    }

    const addContact = () => {
        let newFormData = {...formData};

        _.set(newFormData, 'contacts', [...newFormData.contacts, {
            'title': null,
            'name': null,
            'description': null,
            'prefix': null,
            'avatar_id': null,
            'hidden': false,
            'numbers': [],
        }]);

        setFormData(newFormData);
    }

    const addNumber = (contactIndex) => {
        let newFormData = {...formData};

        _.set(newFormData, 'contacts.' + contactIndex + '.numbers', [..._.get(newFormData, 'contacts.' + contactIndex + '.numbers'), {
            'phone_number': null,
        }]);

        setFormData(newFormData);
    }

    const renderContactItem = ({ item, index: contactIndex }) => {
        return (
            <NestableListAccordionItem index={contactIndex} title={item.title ? item.title[defaultLanguage] : 'Podaj nazwę kontaktu'} removeButtonAction={() => handleRemoveItem('contacts.' + contactIndex, 'contact')}>
                {
                    Object.keys(availableLanguages).map((language) => (
                        <div key={language} className={classNames('language-wrapper', {'active': dataLanguage === language})}>
                            <div>
                                <label className="label">
                                    <span className="label-text">Nazwa kontaktu</span>
                                </label>
                                <Input className={'w-full'} placeholder={'Podaj nazwę kontaktu'} type={'text'} value={ (item.title && item.title[language]) || '' } onChange={({target: {value}}) => handleFormDataChange('contacts.' + contactIndex + '.title', value, language)} />
                                { errors.errors && errors.errors['contacts.' + contactIndex + '.title'] && <Alert className={'text-sm text-white py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors['contacts.' + contactIndex + '.title'][0] }</Alert> }
                                { errors.errors && errors.errors['contacts.' + contactIndex + '.title.' + language] && <Alert className={'text-sm text-white py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors['contacts.' + contactIndex + '.title.' + language][0] }</Alert> }
                            </div>
                            <div>
                                <label className="label">
                                    <span className="label-text">Przedrostek</span>
                                </label>
                                <Input className={'w-full'} placeholder={'Podaj przedrostek kontaktu'} type={'text'} value={ (item.prefix && item.prefix[language]) || '' } onChange={({target: {value}}) => handleFormDataChange('contacts.' + contactIndex + '.prefix', value, language)} />
                                { errors.errors && errors.errors['contacts.' + contactIndex + '.prefix.' + language] && <Alert className={'text-sm text-white py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors['contacts.' + contactIndex + '.prefix.' + language][0] }</Alert> }
                            </div>
                            <div>
                                <label className="label">
                                    <span className="label-text">Imie</span>
                                </label>
                                <Input className={'w-full'} placeholder={'Podaj imię i nazwisko kontaktu'} type={'text'} value={ (item.name && item.name[language]) || '' } onChange={({target: {value}}) => handleFormDataChange('contacts.' + contactIndex + '.name', value, language)} />
                                { errors.errors && errors.errors['contacts.' + contactIndex + '.name.' + language] && <Alert className={'text-sm text-white py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors['contacts.' + contactIndex + '.name.' + language][0] }</Alert> }
                            </div>
                            <div>
                                <label className="label">
                                    <span className="label-text">Opis</span>
                                </label>
                                <Input className={'w-full'} placeholder={'Podaj opis kontaktu'} type={'text'} value={ (item.description && item.description[language]) || '' } onChange={({target: {value}}) => handleFormDataChange('contacts.' + contactIndex + '.description', value, language)} />
                                { errors.errors && errors.errors['contacts.' + contactIndex + '.description.' + language] && <Alert className={'text-sm text-white py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors['contacts.' + contactIndex + '.description.' + language][0] }</Alert> }
                            </div>
                            <div>
                                <label className="label">
                                    <span className="label-text">Avatar</span>
                                </label>

                                { errors.errors && errors.errors['contacts.' + contactIndex + '.description.' + language] && <Alert className={'text-sm text-white py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors['contacts.' + contactIndex + '.description.' + language][0] }</Alert> }
                            </div>
                        </div>
                    ))
                }
                <div>
                    <label className="text-sm justify-start items-center flex">
                        <input type={'checkbox'} className={'checkbox mr-2'} checked={item.hidden || false} onChange={({target: {checked}}) => handleFormDataChange('contacts.' + contactIndex + '.hidden', checked)} />
                        Ukryj grupę na podstronie książki telefonicznej
                    </label>
                    { errors.errors && errors.errors['contacts.' + contactIndex + '.hidden'] && <Alert className={'text-sm text-white py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors['contacts.' + contactIndex + '.hidden'][0] }</Alert> }
                </div>
                <div>
                    <label className="label">
                        <span className="label-text">Numery telefonu</span>
                    </label>

                    <div>
                        {
                            item.numbers.map((number, numberIndex) => (
                                <NestableListAccordionItem key={'number-' + numberIndex} index={numberIndex} title={number.phone_number || 'Podaj numer telefonu'} removeButtonAction={() => handleRemoveItem('contacts.' + contactIndex + '.numbers.' + numberIndex, 'number')}>
                                    <div>
                                        <label className="label">
                                            <span className="label-text">Numer telefonu</span>
                                        </label>
                                        <Input className={'w-full'} placeholder={'Podaj numer telefonu'} type={'text'} value={ number.phone_number || '' } onChange={({target: {value}}) => handleFormDataChange('contacts.' + contactIndex + '.numbers.' + numberIndex + '.phone_number', value)} />
                                        { errors.errors && errors.errors['contacts.' + contactIndex + '.numbers.' + numberIndex + '.phone_number'] && <Alert className={'text-sm text-white py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors['contacts.' + contactIndex + '.numbers.' + numberIndex + '.phone_number'][0] }</Alert> }
                                    </div>
                                </NestableListAccordionItem>
                            ))
                        }
                    </div>

                    { errors.errors && errors.errors['contacts.' + contactIndex + '.numbers'] && <Alert className={'text-sm text-white py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors['contacts.' + contactIndex + '.numbers'][0] }</Alert> }
                </div>
                <div className={'flex items-center justify-end my-4'}>
                    <button className={'button button-blue'} type={'button'} onClick={() => addNumber(contactIndex)}>{ false ? <Spinner/> : 'Dodaj numer' }</button>
                </div>
            </NestableListAccordionItem>
        );
    };

    useEffect(() => {
        fetchGroupData();
    }, [])

    return (
        <>
            <NavbarBar/>

            <div className={'page-wrapper'}>
                <div className={'container'}>
                    <PageTitle title={'Edytuj grupę'} backUrl={'/phone-book'} />

                    <div className={'edit-create-container'}>
                        <ul className="tabs-container">
                            <li className={classNames('pane', {'active': activeTab === 'general'})} onClick={() => setActiveTab('general')}><a href="#">Ogólne</a></li>
                            <li className={classNames('pane', {'active': activeTab === 'contacts'})} onClick={() => setActiveTab('contacts')}><a href="#">Kontakty</a></li>
                        </ul>

                        <LangSwitch fetchingData={fetchingData} availableLanguages={availableLanguages} dataLanguage={dataLanguage} setDataLanguage={setDataLanguage}/>

                        {/*<div className={classNames('left-column', {'full-width': activeTab === fullWidthTab})}>*/}
                        <div className={'left-column full-width'}>
                            {
                                fetchingData ? (
                                    <>
                                        <div className={'tab-content active'}>
                                            <Skeleton className={'w-10'} height={20} />
                                            <Skeleton className={'w-full'} height={48} />
                                            <Skeleton className={'w-10 mt-3'} height={20} />
                                            <Skeleton className={'w-full'} height={48} />
                                            <Skeleton className={'w-2/12 ml-2'} height={7} />
                                            <Skeleton className={'w-3/12 mt-1'} height={25} />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={classNames('tab-content', {'active': activeTab === 'general'})}>
                                            {
                                                Object.keys(availableLanguages).map((language) => (
                                                    <div key={language} className={classNames('language-wrapper', {'active': dataLanguage === language})}>
                                                        <div>
                                                            <label className="label">
                                                                <span className="label-text">Nazwa</span>
                                                            </label>
                                                            <Input className={'w-full'} placeholder={'Podaj nazwę grupy'} type={'text'} value={ (formData.name && formData.name[language]) || '' } onChange={({target: {value}}) => handleFormDataChange('name', value, language)} />
                                                            { errors.errors && errors.errors['name.' + language] && <Alert className={'text-sm text-white py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors['name.' + language][0] }</Alert> }
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                            <div className={'mt-2'}>
                                                <label className="label">
                                                    <span className="label-text">Priorytet</span>
                                                </label>
                                                <Input className={'w-full'} placeholder={'Podaj priorytet grupy'} type={'number'} value={ formData.priority || 100 }  onChange={({target: {value}}) => handleFormDataChange('priority', value)} />
                                                <small className={'ml-2 text-xs'}>Mniejsza liczba = wyższa pozycja</small>
                                                { errors.errors?.priority && <Alert className={'text-sm text-white py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors.priority }</Alert> }
                                            </div>
                                            <div className={'mt-2'}>
                                                <label className="text-sm justify-start items-center flex">
                                                    <input type={'checkbox'} className={'checkbox mr-2'} checked={formData.hidden || false} onChange={({target: {checked}}) => handleFormDataChange('hidden', checked)} />
                                                    Ukryj grupę na podstronie książki telefonicznej
                                                </label>
                                                { errors.errors?.priority && <Alert className={'text-sm text-white py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors.priority }</Alert> }
                                            </div>
                                        </div>
                                        <div className={classNames('tab-content', {'active': activeTab === 'contacts'})}>
                                            <Nestable
                                                maxDepth={1}
                                                items={formData.contacts || []}
                                                onChange={(e) => handleReorder(e, 'contacts')}
                                                renderItem={(e) => renderContactItem(e)}
                                            />
                                            <div className={'flex items-center justify-end mt-4'}>
                                                <button className={'button button-blue'} type={'button'} onClick={addContact}>{ false ? <Spinner/> : 'Dodaj kontakt' }</button>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>

                        {/*{*/}
                        {/*    activeTab !== fullWidthTab && (*/}
                        {/*        <div className="right-column">*/}
                        {/*            <div className={'tab-content'}>*/}

                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    )*/}
                        {/*}*/}

                        <button className={'button button-blue save-button'} type={'button'} onClick={trySaveFormData}>{ false ? <Spinner/> : 'Zapisz' }</button>
                    </div>
                </div>
            </div>
        </>
    );
}