import * as yup from 'yup';
import { setLocale } from 'yup';

const yupInstance = yup;

setLocale({
    mixed: {
        required: 'Pole jest wymagane.',
    },
    string: {
        min: 'Pole musi mieć przynajmniej ${min} znaków.',
        max: 'Pole nie może być dłuższe niż ${max} znaki.',
    },
    number: {
        min: 'Pole musi być nie mniejsze od ${min}.',
        max: 'Pole nie może być większe niż ${min}.',
    },
});

yup.addMethod(yup.string, 'minNullable', function(minLength) {
    return this.test('len', 'Pole musi mieć przynajmniej ' + minLength + ' znaków.', val => val && val.length !== 0 ? val.length > minLength : true);
});

yup.addMethod(yup.object, 'canBeNull', function(minLength) {
    return this.transform(value => value === null ? {} : value);
});

export default yupInstance;