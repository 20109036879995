import React, {useEffect, useRef, useState} from "react";
import { usePopper } from 'react-popper';

import classNames from 'classnames';

import * as Icon from 'react-bootstrap-icons';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function EditPhoneBookGroup({ dataLanguage, setDataLanguage, availableLanguages, fetchingData }) {
    const [isOpen, setIsOpen] = useState(false);
    const boxRef = useRef();
    const tooltipRef = useRef();

    const [activeLanguageName, setActiveLanguageName] = useState('');

    const {styles,attributes} = usePopper(boxRef.current, tooltipRef.current, {
        placement: 'bottom',
    });

    const onClickHeader = () => {
        setIsOpen(!isOpen);
    }

    const setLanguage = (e) => {
        e.preventDefault();

        setIsOpen(false);

        setDataLanguage(e.target.dataset.lang);
        setActiveLanguageName(availableLanguages[e.target.dataset.lang]);
    }

    useEffect(() => {
        setActiveLanguageName(availableLanguages[dataLanguage]);
    }, [availableLanguages])

    return (
        <>
            <div className={'lang-switch'}>
                <div className="dropdown-container" ref={boxRef}>
                    {
                        fetchingData ? (
                            <>
                                <Skeleton baseColor={'#006dbe'} highlightColor={'#0081e1'} className={'w-full h-full'} />
                            </>
                        ) : (
                            <>
                                <button type="button" onClick={onClickHeader}>
                                    <Icon.Globe className={'mr-3'}/>
                                    <span>{ activeLanguageName }</span>
                                    <Icon.CaretDownFill className={'ml-auto'}/>
                                </button>
                            </>
                        )
                    }
                    <ul className={classNames('dropdown', {'dropdown-hidden':!isOpen})} ref={tooltipRef} style={styles.popper} {...attributes.popper}>
                        {
                            Object.keys(availableLanguages).map((language) => (
                                <li key={language}>
                                    <a href="#" onClick={setLanguage} data-lang={language} className={ classNames('dropdown-item', { 'active': dataLanguage === language }) }>{ availableLanguages[language] }</a>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </>
    );
}