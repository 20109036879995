import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import {Alert, Button, Input} from "react-daisyui";

import { sendLoginUserRequest } from "../../../helpers/api-requests/auth";
import Cookies from "universal-cookie";
import Spinner from "../../partials/Spinner";
import {toast} from "react-toastify";

export default function LoginPage(props) {
    const [emailInput, setEmailInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const [errors, setErrors] = useState('');
    const [loading, setLoading] = useState('');

    const navigate = useNavigate();

    const tryLogin = async e => {
        e.preventDefault();

        const response = await sendLoginUserRequest({
            'email': emailInput,
            'password': passwordInput,
        }, () => {
            setLoading(true);
        },() => {
            setLoading(false);
        });

        if(response.data) {
            if(response.status === 200) {
                if(response.data.action === 'two-fa-login') {
                    setErrors('');

                    toast.info((
                        <>
                            <div className={'text-sm'}>Wymagane logowanie dwuetapowe</div>
                        </>
                    ), {
                        hideProgressBar: true,
                    });

                    navigate("/login/2fa", {
                        replace: true,
                        state: {
                            two_fa_type: response.data.two_fa_type,
                            user_id: response.data.user_id,
                        }
                    });
                } else {
                    setErrors('');

                    new Cookies().set('session', response.data.auth.token, { path: '/', expires: new Date(response.data.auth.expires_at) });
                    localStorage.setItem('user', JSON.stringify(response.data.user));

                    navigate("/", {
                        replace: true,
                    });
                }
            } else {
                if (response.errors === 'two-fa-app-configure') {
                    setErrors({
                        errors: {},
                        message: response.message,
                    });

                    navigate("/login/2fa/configure", {
                        replace: true,
                        state: {
                            user_id: response.data.user_id,
                            secret: response.data.secret,
                            two_fa_type: response.data.two_fa_type,
                            qr_code: response.data.qrCode,
                        }
                    });
                } else if (response.errors === 'invalid-credentials' || response.errors === 'account-inactive') {
                    setErrors({
                        errors: {},
                        message: response.message,
                    });
                }
            }
        } else {
            setErrors({...response});
        }
    }

    return (
        <>
            <div className={'container flex items-center justify-center py-40'}>
                <form className={'flex flex-col w-fit'} action={''} onSubmit={tryLogin}>
                    <div>
                        <label className="label">
                            <span className="label-text">E-mail</span>
                        </label>
                        <Input placeholder={'Podaj e-mail'} type={'email'} value={emailInput} disabled={loading} onChange={(event) => setEmailInput(event.target.value)} />
                        { errors.errors?.email && <Alert className={'text-sm py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors.email[0] }</Alert> }
                    </div>
                    <div>
                        <label className="label">
                            <span className="label-text">Hasło</span>
                        </label>
                        <Input placeholder={'Podaj hasło'} type={'password'} value={passwordInput} disabled={loading} onChange={(event) => setPasswordInput(event.target.value)} />
                        { errors.errors?.password && <Alert className={'text-sm py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors.password[0] }</Alert> }
                    </div>

                    <Button className={'self-end mt-4 text-xs'} type={'submit'} size={'sm'} disabled={loading}>{ loading ? <Spinner/> : 'Zaloguj' }</Button>

                    {
                        (errors && (errors.status === undefined || (errors.status !== 400))) && <Alert className={'text-sm py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.message }</Alert>
                    }
                </form>
            </div>
        </>
    );
}