import {Outlet, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import Cookies from "universal-cookie";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../../assets/scss/styles.scss';

export default function App() {
    const navigate = useNavigate();

    useEffect(() => {
        if(new Cookies().get('session')) {
            navigate("/", {
                replace: true,
            });
        }
    });

    return (
        <>
            <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <Outlet />
                <ToastContainer />
            </SkeletonTheme>
        </>
    )
};