import React, {useRef, useState} from "react";
import { usePopper } from 'react-popper';

import classNames from 'classnames';

import * as Icon from 'react-bootstrap-icons';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function EditPhoneBookGroup({ title, children, removeButtonAction }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className={'accordion-item'}>
                <div className={'accordion-header'}>
                    <div onClick={(e) => setIsOpen(!isOpen)} className={classNames('accordion-button cursor-pointer', { 'font-medium': isOpen })}>
                        {
                            !isOpen ? <Icon.CaretDownFill className={'mr-2'}/> : <Icon.CaretUpFill className={'mr-2'}/>
                        }

                        { title !== '' ? title : 'Podaj nazwę kontaktu' }
                    </div>
                    <div className={'actions'}>
                        <div className={'action cursor-pointer'} onClick={removeButtonAction}>
                            <Icon.Trash size={13}/>
                        </div>
                    </div>
                </div>
                <div className={classNames('accordion-collapse', { 'hidden': !isOpen })}>
                    { children }
                </div>
            </div>
        </>
    );
}