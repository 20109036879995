import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Cookies from 'universal-cookie';
import { sendTwoFactorLoginRequest } from "../../../helpers/api-requests/auth";
import {Alert, Button, Input} from "react-daisyui";
import Spinner from "../../partials/Spinner";

export default function LoginTwoFactorPage() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { two_fa_type = '', user_id = '' } = state ?? {};
    const [loading, setLoading] = useState('');

    useEffect(() => {
        if(!state) {
            navigate("/login", {
                replace: true,
            });
        }
    })

    const [codeInput, setCodeInput] = useState('');
    const [errors, setErrors] = useState('');

    const tryLogin = async (e) => {
        e.preventDefault();

        const response = await sendTwoFactorLoginRequest({
            'user_id': user_id,
            'code': codeInput,
        }, () => {
            setLoading(true);
        },() => {
            setLoading(false);
        });

        if(response.data) {
            setErrors('');

            new Cookies().set('session', response.data.auth.token, { path: '/', expires: new Date(response.data.auth.expires_at) });
            localStorage.setItem('user', JSON.stringify(response.data.user));

            navigate("/", {
                replace: true,
            });

        } else {
            setErrors({...response});
        }
    }

    return (
        <>
            <div className={'container flex items-center justify-center py-40'}>
                <form className={'flex flex-col w-fit'} action={''} onSubmit={tryLogin}>
                    <div>
                        <label className="label">
                            <span className="label-text">Kod logowania z { two_fa_type === 'app' ? 'aplikacji' : 'e-maila' }</span>
                        </label>
                        <Input placeholder={'Podaj kod'} type={'number'} value={codeInput} disabled={loading} onChange={(event) => setCodeInput(event.target.value)} />
                        { errors.errors?.code && <Alert className={'text-sm py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors.code[0] }</Alert> }
                    </div>

                    <Button className={'self-end mt-4 text-xs'} type={'submit'} size={'sm'} disabled={loading}>{ loading ? <Spinner/> : 'Zaloguj' }</Button>

                    {
                        (errors && (errors.status === undefined || (errors.status !== 400))) && <Alert className={'text-sm py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.message }</Alert>
                    }
                </form>
            </div>
        </>
    );
}