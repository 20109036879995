import React, {useState, useEffect} from "react";
import NavbarBar from "../../partials/Navbar/NavbarBar";
import _ from "lodash";
import {sendDirectoryDataRequest} from "../../../helpers/api-requests/library";
import FsLightbox from 'fslightbox-react';

export default function LibraryPage() {
    const [storagePath, setStoragePath] = useState('uploads');
    const [responseData, setResponseData] = useState([]);
    const [fetchingData, setFetchingData] = useState(false);
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    const [allowedSelectionType, setAllowedSelectionType] = useState('all'); //all | files | directories | images
    const [files, setFiles] = useState([]);
    const [directories, setDirectories] = useState([]);

    const fetchDirectoryData = async e => {
        const response = await sendDirectoryDataRequest({
            'path': storagePath,
        }, () => {
            setFetchingData(true);
        }, () => {
            setFetchingData(false);
        });

        if(response.status === 200) {
            console.log(response);

            setFiles(response.data.files);
            setDirectories(response.data.directories);

            console.log(files.filter(file => file.type === "image").map(file => file.url));
        }
    }

    const fsLightboxToggler = (e, number) => {
        e.preventDefault();

        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    useEffect(() => {
        fetchDirectoryData();
    }, [])

    return (
        <>
            <NavbarBar/>

            <div className={'page-wrapper'}>
                <div className={'container library'}>
                    {
                        files.map((file, index) => {
                            if(allowedSelectionType === "all" || (allowedSelectionType === "files" && file.type === "file") || (allowedSelectionType === "images" && file.type === "image")) {
                                return (
                                    <div className={'item'} key={index}>
                                        <input type={'checkbox'} className={'checkbox'} />

                                        <p>Name: {file.name}</p>
                                        <p>Data: { new Date(file.created_at).toLocaleDateString('pl-PL') + ' ' + new Date(file.created_at).toLocaleTimeString('pl-PL') }</p>
                                        <p>Opis pliku: {file.description}</p>

                                        {
                                            file.type === "image" ? (
                                                <a href={file.url} onClick={(e) => fsLightboxToggler(e, index)}>
                                                    <div className={'image'} style={{ backgroundImage: `url(${file.url})` }}/>
                                                </a>
                                            ) : (
                                                <>
                                                </>
                                            )
                                        }
                                    </div>
                                )
                            }

                            return null;
                        })
                    }

                    <FsLightbox
                        toggler={lightboxController.toggler}
                        sources={files.filter(file => file.type === "image").map(file => file.url)}
                        sourceIndex={lightboxController.slide}
                    />
                </div>
            </div>
        </>
    );
}