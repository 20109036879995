import axiosInstance from "../../components/axiosInstance";

export const sendDirectoryDataRequest = async (data, callbackBefore = () => {}, callbackAfter = () => {}) => {
    callbackBefore();

    return await axiosInstance.get('https://dev.w07.pl/api/v1/admin/library/directory?' + new URLSearchParams(data).toString()).then(function (response) {
        callbackAfter();

        return response.data;
    }).catch(function (error) {
        callbackAfter();

        return error.response.data;
    });
}