import { Outlet } from "react-router-dom";
import React, {useEffect} from "react";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import 'react-nestable/dist/styles/index.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../../assets/scss/styles.scss';
import {useTheme} from "react-daisyui";

export default function AppProtected() {
    const navigate = useNavigate();

    const { theme, setTheme } = useTheme();

    document.getElementsByTagName('html')[0].setAttribute('data-theme', 'light')
    window.localStorage.setItem('sb-react-daisyui-preview-theme', 'light')
    setTheme('light')

    useEffect(() => {
        if(!new Cookies().get('session'))
        {
            navigate("/login", {
                replace: true,
            });
        }
    });

    return (
        <>
            <SkeletonTheme baseColor="#ebebeb" highlightColor="#f5f5f5">
                <Outlet />
                <ToastContainer />
            </SkeletonTheme>
        </>
    )
};