import React from "react";
import LogoutUser from "./auth/LogoutUser";
import NavbarBar from "../partials/Navbar/NavbarBar";

export default function DashboardPage() {
    return (
        <>
            <NavbarBar/>
            Dashboard
        </>
    );
}