import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";

import * as Icon from 'react-bootstrap-icons';

import { toast } from 'react-toastify';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


import NavbarBar from "../../partials/Navbar/NavbarBar";
import Pagination from "../../partials/Pagination";
import Spinner from "../../partials/Spinner";

import { sendListGroupsRequest, sendRemoveGroupRequest } from "../../../helpers/api-requests/phone-book";

export default function PhoneBookGroupsList() {
    const [responseData, setResponseData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [fetchingData, setFetchingData] = useState(false);
    const [removingGroup, setRemovingGroup] = useState(-1);

    const [defaultLanguage, setDefaultLanguage] = useState('pl');


    const updateGroups = async (e, withoutFetch = false) => {
        const response = await sendListGroupsRequest({
            'orderBy': 'created_at',
            'order': 'desc',
            'perPage': 10,
            'page': currentPage,
        }, () => {
            if(!withoutFetch) {
                setFetchingData(true);
            }
        },() => {
            setFetchingData(false);
        });

        if(response.status === 200) {
            setResponseData(response);

            if(currentPage > response?.meta?.last_page) {
                setCurrentPage(response.meta.last_page);
            }
        }
    }

    const tryRemoveGroup = async (event, group) => {
        const response = await sendRemoveGroupRequest(group.id, () => {
            setRemovingGroup(group.id);
        },() => {
            setRemovingGroup(-1);
        });

        if(response.status === 200) {
            toast.success(response.message, {
                hideProgressBar: true,
            });

            updateGroups(null, true);
        }
    };

    useEffect(() => {
        updateGroups();
    }, [currentPage])

    return (
        <>
            <NavbarBar/>

            <div className={'page-wrapper'}>
                <div className={'container'}>
                    <div className={'overflow-x-auto'}>
                        <div className={'table'}>
                            {
                                fetchingData ? (
                                    <>
                                        <div className={'table-header'}>
                                            <span><Skeleton className={'w-6/12'} height={20} /></span>
                                            <span><Skeleton className={'w-6/12'} height={20} /></span>
                                            <span><Skeleton className={'w-6/12'} height={20} /></span>
                                            <span><Skeleton className={'w-6/12'} height={20} /></span>
                                        </div>
                                        <div>
                                            {
                                                Array(10).fill().map((group, index) => {
                                                    return (
                                                        <div className={'row'} key={index}>
                                                            <span className={'cell'}><Skeleton className={'w-6/12'} height={20} /></span>
                                                            <span className={'cell'}><Skeleton className={'w-6/12'} height={20} /></span>
                                                            <span className={'cell'}><Skeleton className={'w-6/12'} height={20} /></span>
                                                            <span className={'cell'}><Skeleton className={'w-6/12'} height={20} /></span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={'table-header'}>
                                            <span>Priorytet</span>
                                            <span>Nazwa</span>
                                            <span>Kontakty</span>
                                            <span>Akcje</span>
                                        </div>
                                        <div>
                                            {
                                                responseData.data?.map((group, index) => {
                                                    const contacts = group.contacts?.reduce((data, item) => data += item.title[defaultLanguage] + ', ', '').slice(0, -2).substr(0, 90);

                                                    return (
                                                        <div className={'row'} key={index}>
                                                            <span className={'cell'}>{ group.priority }</span>
                                                            <span className={'cell'}>{ group.name[defaultLanguage] }</span>
                                                            <span className={'cell'}>{ contacts?.length > 0 ? contacts + '...' : 'Brak' }</span>
                                                            <span className={'cell cell-horizontal'}>
                                                                <Link className={'flex flex-col items-center justify-center text-sm text-green-800 cursor-pointer'} to={`/phone-book/${group.id}/edit`}>
                                                                    <Icon.Pen className={'mb-2'}/>
                                                                    Edytuj
                                                                </Link>

                                                                <div onClick={event => tryRemoveGroup(event, group)} className={'flex flex-col items-center justify-center text-sm text-red-800 cursor-pointer'}>
                                                                    {
                                                                        removingGroup === group.id ? (
                                                                            <Spinner/>
                                                                        ) : (
                                                                            <>
                                                                                <Icon.Trash className={'mb-2'}/>
                                                                                Usuń
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                            </span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                        <Pagination currentPage={currentPage} lastPage={responseData?.meta?.last_page} links={responseData?.meta?.links} setCurrentPage={setCurrentPage}/>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}