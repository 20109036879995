import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider} from "@tanstack/react-query";

import reportWebVitals from './reportWebVitals';

import App from "./components/layouts/App";
import AppProtected from "./components/layouts/AppProtected";

import DashboardPage from "./components/pages/DashboardPage";

import LoginPage from "./components/pages/auth/LoginPage";
import LoginTwoFactorPage from "./components/pages/auth/LoginTwoFactorPage";
import ConfigureTwoFactorPage from "./components/pages/auth/ConfigureTwoFactorPage";

import NotFoundPage from "./components/pages/NotFoundPage";
import PhoneBookGroupsList from "./components/pages/phone-book/PhoneBookGroupsList";
import EditPhoneBookGroup from "./components/pages/phone-book/EditPhoneBookGroup";
import LibraryPage from "./components/pages/library/LibraryPage";

const root = ReactDOM.createRoot(document.getElementById('root'));

const routes = [
    { path: "/login", Element: LoginPage, exact: true },
    { path: "/login/2fa", Element: LoginTwoFactorPage, exact: true },
    { path: "/login/2fa/configure", Element: ConfigureTwoFactorPage, exact: true },
];

const routesProtected = [
    { path: "/", Element: DashboardPage, exact: true },
    { path: "/phone-book", Element: PhoneBookGroupsList, exact: true },
    { path: "/phone-book/:group_id/edit", Element: EditPhoneBookGroup, exact: true },
    { path: "/library", Element: LibraryPage, exact: true },
];

const queryClient = new QueryClient()

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Routes>
                    <Route key="*" path="*" element={<NotFoundPage />}/>
                    <Route element={<App />}>
                        {
                            routes.map(({ path, Element, exact }) => (
                                <Route key={path} path={path} element={<Element />} exact={exact}/>
                            ))
                        }
                    </Route>
                    <Route element={<AppProtected />}>
                        {
                            routesProtected.map(({ path, Element, exact }) => (
                                <Route key={path} path={path} element={<Element />} exact={exact}/>
                            ))
                        }
                    </Route>
                </Routes>
            </BrowserRouter>
        </QueryClientProvider>
    </React.StrictMode>
);

reportWebVitals();
