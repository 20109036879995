import * as Icon from 'react-bootstrap-icons';

export default function Pagination({currentPage, lastPage, links, setCurrentPage}) {
    links = links?.slice(1, -1).map(
        (link) => ({
            'page': link.url?.split('page=').pop(),
            'active': link.active,
            'label': link.label,
        })
    );

    const previousPage = async e => {
        e.preventDefault();

        if(currentPage > 1) {
            setCurrentPage(--currentPage);
        }
    }

    const nextPage = async e => {
        e.preventDefault();

        if(currentPage < lastPage) {
            setCurrentPage(++currentPage);
        }
    }

    const setPage = async e => {
        e.preventDefault();

        setCurrentPage(e.target.dataset.page);
    }

    return (
        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
                <a onClick={previousPage} href={'#'} className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Poprzednia</a>
                <a onClick={nextPage} href={'#'} className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Następna</a>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-end">
                <div>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                        <a onClick={previousPage} href={'#'} className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                            <span className="sr-only">Poprzednia</span>
                            <Icon.ChevronLeft className="h-5 w-5" />
                        </a>

                        {
                            links?.map((link, index) => (
                                <div key={'page-' + index}>
                                    {
                                        link.page ? (
                                            link.active ? (
                                                <a
                                                    aria-current="page"
                                                    className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                                >{ link.label }</a>
                                            ) : (
                                                <a
                                                    onClick={setPage}
                                                    href={'#'}
                                                    data-page={link.page}
                                                    className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                                >{ link.label }</a>
                                            )
                                        ) : (
                                            <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">{ link.label }</span>
                                        )
                                    }
                                </div>
                            ))
                        }

                        <a onClick={nextPage} href={'#'} className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                            <span className="sr-only">Następna</span>
                            <Icon.ChevronRight className="h-5 w-5" />
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    )
}