import React, {useRef, useState} from "react";

import classNames from 'classnames';

import * as Icon from 'react-bootstrap-icons';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {Link} from "react-router-dom";

export default function PageTitle({ title, backUrl }) {
    return (
        <>
            <Link to={backUrl} className={'back-button'}><Icon.CaretLeftFill className={'mr-1'}/>Powrót</Link>

            <div className={'page-title'}>
                <h1>{ title }</h1>
            </div>
        </>
    );
}