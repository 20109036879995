import React from "react";
import {Badge, Button, Dropdown, Form, Indicator, Input, Navbar} from "react-daisyui";
import * as Icon from 'react-bootstrap-icons';
import LogoutUser from "../../pages/auth/LogoutUser";
import { Link } from "react-router-dom";

export default function NavbarBar() {
    return (
        <>
            <div className="flex w-full p-4 items-center justify-center gap-2">
                <Navbar>
                    <Navbar.Start>
                        <Dropdown>
                            <Button color="ghost" shape="circle" tabIndex={0}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7"/></svg>
                            </Button>
                            <Dropdown.Menu tabIndex={0} className="menu-compact w-52">
                                <li><Link to="/">Pulpit</Link></li>
                                <li><Link to="/phone-book">Książka Telefoniczna</Link></li>
                                <li><Link to="/library">Media</Link></li>
                                <LogoutUser/>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Navbar.Start>
                    {/*<Navbar.End className="navbar-end">*/}
                    {/*    <Button color="ghost" shape="circle">*/}
                    {/*        <Indicator item={<Badge size="xs" color="primary" />}>*/}
                    {/*            <Icon.Bell size={18}/>*/}
                    {/*        </Indicator>*/}
                    {/*    </Button>*/}
                    {/*    <Dropdown vertical="end">*/}
                    {/*        <Button color="ghost" className="avatar mx-3.5" shape="circle">*/}
                    {/*            <div className="w-10 rounded-full">*/}
                    {/*                <img src="https://api.lorem.space/image/face?hash=33791" />*/}
                    {/*            </div>*/}
                    {/*        </Button>*/}
                    {/*        <Dropdown.Menu className="w-52 menu-compact">*/}
                    {/*            <li>*/}
                    {/*                <a className="justify-between">*/}
                    {/*                    Profile*/}
                    {/*                    <span className="badge">New</span>*/}
                    {/*                </a>*/}
                    {/*            </li>*/}
                    {/*            <Dropdown.Item>Settings</Dropdown.Item>*/}
                    {/*            <Dropdown.Item>Logout</Dropdown.Item>*/}
                    {/*        </Dropdown.Menu>*/}
                    {/*    </Dropdown>*/}
                    {/*</Navbar.End>*/}
                </Navbar>
            </div>

            {/*<LogoutUser />*/}
        </>
    );
}