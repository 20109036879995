import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import React, {useState} from "react";
import { sendLogoutUserRequest } from "../../../helpers/api-requests/auth";
import {Alert, Button} from "react-daisyui";
import Spinner from "../../partials/Spinner";

export default function LogoutUser() {
    const [errors, setErrors] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState('');

    // new Cookies().remove('session');

    // localStorage.removeItem('user');

    const tryLogoutUser = async e => {
        e.preventDefault();

        const response = await sendLogoutUserRequest(null, () => {
            setLoading(true);
        },() => {
            setLoading(false);
        });

        setErrors({...response});

        if(response.status === 200 || response.status === 401) {
            new Cookies().remove('session');
            localStorage.removeItem('user');

            navigate("/login", {
                replace: true,
            });
        }
    }

    return (
        <>
            <li>
                <a onClick={tryLogoutUser}>{ loading ? <Spinner/> : 'Wyloguj' }</a>
            </li>

            {
                (errors && (errors.status === undefined || (errors.status !== 400))) && <Alert className={'text-sm py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.message }</Alert>
            }
        </>
    );
}