import axiosInstance from "../../components/axiosInstance";

export const sendListGroupsRequest = async (data, callbackBefore = () => {}, callbackAfter = () => {}) => {
    callbackBefore();

    return await axiosInstance.get('https://dev.w07.pl/api/v1/admin/phone-book/index?' + new URLSearchParams(data).toString()).then(function (response) {
        callbackAfter();

        return response.data;
    }).catch(function (error) {
        callbackAfter();

        return error.response.data;
    });
}

export const sendRemoveGroupRequest = async (data, callbackBefore = () => {}, callbackAfter = () => {}) => {
    callbackBefore();

    return await axiosInstance.delete('https://dev.w07.pl/api/v1/admin/phone-book/' + data).then(function (response) {
        callbackAfter();

        return response.data;
    }).catch(function (error) {
        callbackAfter();

        return error.response.data;
    });
}

export const sendFetchGroupDataRequest = async (data, callbackBefore = () => {}, callbackAfter = () => {}) => {
    callbackBefore();

    return await axiosInstance.get('https://dev.w07.pl/api/v1/admin/phone-book/' + data).then(function (response) {
        callbackAfter();

        return response.data;
    }).catch(function (error) {
        callbackAfter();

        return error.response.data;
    });
}

export const sendGroupUpdateRequest = async (id, data, callbackBefore = () => {}, callbackAfter = () => {}) => {
    callbackBefore();

    return await axiosInstance.patch('https://dev.w07.pl/api/v1/admin/phone-book/' + id + '/update', data).then(function (response) {
        callbackAfter();

        return response.data;
    }).catch(function (error) {
        callbackAfter();

        return error.response.data;
    });
}
