import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import SVG from 'react-inlinesvg';
import { sendTwoFactorConfigureRequest } from "../../../helpers/api-requests/auth";
import {Alert, Button, Input} from "react-daisyui";
import Spinner from "../../partials/Spinner";

export default function ConfigureTwoFactorPage() {
    const navigate = useNavigate();
    const [errors, setErrors] = useState('');
    const { state } = useLocation();
    const [loading, setLoading] = useState('');
    const { qr_code = '', user_id = '', secret = '', two_fa_type = '' } = state ?? {};

    useEffect(() => {
        if(!state) {
            navigate("/login", {
                replace: true,
            });
        }
    }, [navigate, state])

    const tryConfigure = async (e) => {
        e.preventDefault();

        const response = await sendTwoFactorConfigureRequest({
            'user_id': user_id,
            'secret': secret,
        }, () => {
            setLoading(true);
        },() => {
            setLoading(false);
        });

        if(response.status === 200) {
            setErrors('');

            navigate("/login/2fa", {
                replace: true,
                state: {
                    two_fa_type: two_fa_type,
                    user_id: user_id,
                }
            });
        } else {
            setErrors({...response});
        }
    }

    return (
        <>
            <div className={'container flex items-center justify-center py-40'}>
                <form className={'flex flex-col items-center justify-center w-fit max-w-sm'} action={''} onSubmit={tryConfigure}>
                    <p className="mb-0">Aby kontynuować, musisz skonfigurować aplikację <a href="https://support.google.com/accounts/answer/1066447?hl=pl">Google Authenticator</a> (lub inną). W przeciwnym razie nie będziesz mógł/a się zalogować.</p>

                    { qr_code && <SVG className={'my-10'} src={qr_code} /> }
                    <p className="mb-0">Skonfiguruj uwierzytelnianie dwuskładnikowe, skanując powyższy kod QR. Alternatywnie możesz wpisać ręcznie kod <b>{ secret }</b></p>

                    <Button className={'mt-4 text-xs'} type={'submit'} size={'md'} disabled={loading}>{ loading ? <Spinner/> : 'Zatwierdź' }</Button>

                    {
                        (errors && (errors.status === undefined || (errors.status !== 400))) && <Alert className={'text-sm py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.message }</Alert>
                    }
                </form>
            </div>
        </>
    );
}